<template>
  <div>
    <rankings-table v-if="authoritySlug === 'super-admin' || permissions.access_ranking"></rankings-table>
    <rankings-table-as v-if="authoritySlug === 'success-advisor'"></rankings-table-as>
  </div>
</template>

<script>
import RankingsTable from "./RankingsTable";
import RankingsTableAs from "./RankingsTableAS";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "Rankings",
  
  components: {
    RankingsTable,
    RankingsTableAs
  },

  beforeMount() {
    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  }
};
</script>

<template>
  <div>
    <toolbar :toolbarName="dynamicToolbarName" :openDetails="true" />

    <search-bar v-if="!openDetails" v-model="search" :noSecondaryInput="false"
      :noTertiaryInput="false" :noQuaternaryInput="false" :noSubTertiaryInput="noSubTertiaryInput" :noFourthInput="false"
      :hasClearFilters="false" smallFields>
      <template slot="tertiary-input">
        <simple-select v-model="selectedRankingType" :items="rankingTypes" itemText="text" itemValue="value" class="mr-2"
          height="0" :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
          @custom-change="changeRankingType()" />
      </template>

      <template slot="sub-tertiary-input">
        <v-form ref="subField">
          <simple-select v-if="selectedRankingType === 'regional_ranking'" v-model="selectedAS" :items="successAdvisors"
            itemText="name" itemValue="id" style="margin-top: 0.93rem;" class="mr-2" placeholder="Selecione AS" height="0"
            :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }" required
            :rules="requiredRules" @custom-change="getRegionalRanking()" />

          <simple-select v-if="selectedRankingType === 'state_ranking'" v-model="selectedState" :items="states"
            itemText="name" itemValue="id" style="margin-top: 0.93rem;" class="mr-2" placeholder="Selecione Estado"
            height="0" :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }" required
            :rules="requiredRules" @custom-change="getStateRanking()" />
        </v-form>
      </template>

      <template slot="fourth-input">
        <simple-select v-model="currentHarvest" :items="this.harvests.map(item => item.harvest)" itemText="harvest"
          itemValue="harvest" placeholder="Safra" height="0"
          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200 }"
          @custom-change="changeHarvest()" />
      </template>
      <template slot="quaternary-input">
        <div>
          <text-button class="ml-4" color="black" noMargin margin0 @callAction="filterModal = true" label="Filtrar" />
        </div>
      </template>
    </search-bar>
    <v-tabs fixed-tabs background-color="#e3e3e3" ligth class="font-weight-bold" v-model="selectedIql"
      @change="changeIql()">
      <v-tab>
        IQL Geral
      </v-tab>
      <v-tab>
        IQL Fertilidade
      </v-tab>
      <v-tab>
        IQL Manejo
      </v-tab>
      <v-tab>
        IQL Financeiro
      </v-tab>
    </v-tabs>

    <v-data-table :key="componentKey" :footer-props="{
      'items-per-page-text': 'Linhas por página',
      'items-per-page-all-text': 'Todos',
    }" :headers="headers" :items="data" :search="search.text" :loading="fetching" :loading-text="objectName ?
  'Carregando dados de ' + objectName + ', por favor aguarde...' :
  'Carregando dados, por favor aguarde...'" class="px-6 pt-6 yellow-border rounded-xl"
      no-data-text="Não foram encontrados registros." no-results-text="Nenhum registro bate com a busca.">
      <template v-slot:[`item.position`]="{ item }">
        {{ item.position }}º
      </template>

      <template v-slot:[`item.fertility_iql`]="{ item }">
        <router-link to="" @click.native="redirectFertility(item.farm_id, item.harvest, item)">
          {{ item.fertility_iql }}
        </router-link>
      </template>

      <template v-slot:[`item.management_iql`]="{ item }">
        <router-link to="" @click.native="redirectManagement(item)">
          {{ item.management_iql }}
        </router-link>
      </template>

      <template v-slot:[`item.financial_iql`]="{ item }">
        <router-link to="" @click.native="redirectFinancial(item.farm_id, item.harvest, item)">
          {{ item.financial_iql }}
        </router-link>
      </template>

    </v-data-table>
    <v-dialog v-model="filterModal" scrollable transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-btn icon dark @click="filterModal = false" class="mx-5">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Filtros</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-12">
          <v-container>
            <span class="font-weight-bold font-18 text">Sementeiro</span>
            <v-checkbox v-model="filters.is_sower" :value="true" label="Sim" />
            <v-checkbox v-model="filters.is_sower" :value="false" label="Não" />
            <span class="font-weight-bold font-18 text">Área</span>
            <v-checkbox v-model="filters.farm_size" value="0 a 500" label="0 - 500ha" />
            <v-checkbox v-model="filters.farm_size" value="500 a 1000" label="500 - 1000ha" />
            <v-checkbox v-model="filters.farm_size" value="1000 a 3000" label="1000 - 3000ha" />
            <v-checkbox v-model="filters.farm_size" value="acima de 3000" label="Acima de 3000" />
            <span class="font-weight-bold font-18 text">Irrigação</span>
            <v-checkbox v-model="filters.irrigation" value="Sem irrigação" label="Sem irrigação" />
            <v-checkbox v-model="filters.irrigation" value="Sim, menos de 50% da área" label="< 50% da área irrigada" />
            <v-checkbox v-model="filters.irrigation" value="Sim, mais de 50% da área" label="> 50% da área irrigada" />
            <span class="font-weight-bold font-18 text">Tipo de área</span>
            <v-checkbox v-model="filters.area_type" value="1" label="Própria" />
            <v-checkbox v-model="filters.area_type" value="2" label="Arrendada" />
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="saveFilters">Salvar</v-btn>
          <v-btn color="error" text @click="filterModal = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Toolbar from "@/components/crud/Toolbar";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import TextButton from "@/components/buttons/TextButton";

import Rankings from "@/domain/rankings/rankings";
import Harvests from "@/domain/harvests/harvests";
import Users from "@/domain/users/users";

import PageableMixin from "@/support/utils/pageable.mixin";
import { getItem } from '@/services/local-storage.service';

export default {
  name: 'RankingsTable',

  mixins: [PageableMixin],

  components: {
    Toolbar,
    SearchBar,
    SimpleSelect,
    TextButton,
  },

  data: () => ({
    dynamicToolbarName: 'Ranking de Fazendas',
    openDetails: false,
    objectName: 'ranking de fazendas',

    search: {},
    fetching: true,

    headers: [
      { text: 'Posição', value: 'position', sortable: false },
      { text: 'Proprietário', value: 'producer', sortable: false },
      { text: 'Fazenda', value: 'farm', sortable: false },
      { text: 'IQL Geral', value: 'farm_iql' },
      { text: 'IQL Fertilidade', value: 'fertility_iql' },
      { text: 'IQL Manejo', value: 'management_iql' },
      { text: 'IQL Financeiro', value: 'financial_iql' },
    ],

    selectedIql: 0,

    iqls: [
      { text: 'IQL Geral', value: 'farm_iql' },
      { text: 'IQL Fertilidade', value: 'fertility_iql' },
      { text: 'IQL Manejo', value: 'management_iql' },
      { text: 'IQL Financeiro', value: 'financial_iql' },
    ],

    selectedRankingType: 'national_ranking',

    rankingTypes: [
      { text: 'Ranking Nacional', value: 'national_ranking' },
      { text: 'Ranking Regional', value: 'regional_ranking' },
      { text: 'Ranking Estadual', value: 'state_ranking' },
    ],

    selectedRegionalRanking: false,
    noSubTertiaryInput: true,

    rankingService: null,
    harvestService: null,
    userService: null,
    currentHarvest: null,
    selectedAS: null,
    selectedState: null,

    payload: {},
    data: [],
    harvests: [],
    successAdvisors: [],
    states: [],

    componentKey: 0,
    authoritySlug: '',
    permissions: '',

    filterModal: false,
    filters: {
      is_sower: [],
      farm_size: [],
      area_type: [],
      irrigation: [],
    },

    requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
  }),

  methods: {
    async getHarvests() {
      const loader = this.$loading.show();
      this.harvests = [];

      await this.harvestService
        .all()
        .then((result) => {
          this.harvests = result.data;
        });

      loader.hide();
    },

    async getNationalRanking() {
      this.fetching = true;
      this.data = [];
      this.states = [];

      await this.rankingService.getNationalRanking(this.currentHarvest, this.filters)
        .then(result => {
          this.data = this.setPositions(result, 'farm_iql');
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: 'error'
          });
        });

      this.fetching = false;
    },

    async getRegionalRanking() {
      this.fetching = true;
      this.data = [];

      await this.rankingService
        .getRegionalRanking(this.selectedAS, this.currentHarvest, this.filters)
        .then(result => {
          result.forEach(item => {
            this.data.push(item);
          });
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: 'error'
          });
        });

      this.fetching = false;
    },

    async getStateRanking() {
      this.fetching = true;
      this.data = [];

      await this.rankingService
        .getStateRanking(this.selectedState, this.currentHarvest, this.filters)
        .then(result => {
          result.forEach(item => {
            this.data.push(item);
          });
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: 'error'
          });
        });

      this.fetching = false;
    },

    setPositions(data, iql) {
      let newData;
      if (iql === 'farm_iql')
        newData = this.sortFarmIql(data);
      else
        newData = this.sortIql(data, iql)

      return newData
    },
    sortIql(data, iql) {
      let iqlValue = 0;
      let position = 0;
      const newData = data.map((ranking, index) => {
        if (ranking[iql] != iqlValue) {
          iqlValue = ranking[iql]
          position = index + 1
          return { ...ranking, position }
        }
        return { ...ranking, position }
      })
      return newData;
    },
    sortFarmIql(data) {
      let position = 0;
      let farmIql = 0;
      let fertilityIql = 0;
      let managementIql = 0;
      let financialIql = 0;
      const newData = data.map((ranking, index) => {
        if (ranking.farm_iql != farmIql) { //Primeiro critério
          farmIql = ranking.farm_iql;
          fertilityIql = ranking.fertility_iql;
          managementIql = ranking.management_iql;
          financialIql = ranking.financial_iql;

          position = index + 1
          return { ...ranking, position }
        }
        if (ranking.fertility_iql != fertilityIql) {
          fertilityIql = ranking.fertility_iql;
          managementIql = ranking.management_iql;
          financialIql = ranking.financial_iql;

          position = index + 1
          return { ...ranking, position }
        }
        if (ranking.management_iql != managementIql) {
          managementIql = ranking.management_iql;
          financialIql = ranking.financial_iql;

          position = index + 1
          return { ...ranking, position }
        }
        if (ranking.financial_iql != financialIql) {
          financialIql = ranking.financial_iql;

          position = index + 1
          return { ...ranking, position }
        }
        return { ...ranking, position }
      })
      return newData;
    },

    getSuccessAdvisors() {
      const loader = this.$loading.show();
      this.successAdvisors = [];

      this.userService.getSuccessAdvisors()
        .then((result) => {
          result.data.map((itm) => {
            this.successAdvisors.push(itm);
          })
        });

      loader.hide();
    },

    changeIql() {
      const mapIql = ['farm_iql', 'fertility_iql', 'management_iql', 'financial_iql'];
      const iql = mapIql[this.selectedIql];

      this.data.sort((a, b) => {
        const valueA = typeof (a[iql]) === 'string' ? -10 : a[iql];
        const valueB = typeof (b[iql]) === 'string' ? -10 : b[iql];
        return valueB - valueA
      }
      );

      this.data = this.setPositions(this.data, iql);
      this.componentKey += 1;
    },

    changeRankingType() {
      this.selectedAS = null;
      this.selectedState = null;

      if (this.selectedRankingType === 'national_ranking') {
        this.noSubTertiaryInput = true;
        this.getNationalRanking(this.currentHarvest);
      } else {
        this.noSubTertiaryInput = false;
      }
    },

    changeHarvest() {

      if (this.selectedRankingType !== 'national_ranking' &&
        !this.$refs.subField.validate()) {
        return;
      }

      this.selectedRankingType === 'national_ranking' && this.getNationalRanking(this.currentHarvest);
      this.selectedRankingType === 'regional_ranking' && this.getRegionalRanking(this.selectedAS, this.currentHarvest)
      this.selectedRankingType === 'state_ranking' && this.getStateRanking(this.selectedState, this.currentHarvest);
    },

    getCurrentHarvest() {
      const date = new Date();
      let year = date.getFullYear();
      const month = date.getMonth() + 1;

      // safra (ano da colheita) vai de agosto a julho
      if ([8, 9, 10, 11, 12].includes(month)) {
        this.currentHarvest = year + 1;
      } else if ([1, 2, 3, 4, 5, 6, 7].includes(month)) {
        this.currentHarvest = year;
      }
    },

    redirectFertility(farmId, harvest, farm) {
      if (this.authoritySlug === 'super-admin' || this.permissions.access_records) {
        if (farm.fertility_iql !== '--')
          this.navigateTo('fertility-record-details', { farmId: farmId, harvest: harvest });
      }
    },

    redirectManagement(farm) {
      if (this.authoritySlug === 'super-admin' || this.permissions.access_records)
        if (farm.management_iql !== '--')
          this.navigateTo('management-records');
    },

    redirectFinancial(farmId, harvest, farm) {
      if (this.authoritySlug === 'super-admin' || this.permissions.access_records)
        if (farm.financial_iql !== '--')
          this.navigateTo('financial-record-details', { farmId: farmId, harvest: harvest });
    },

    initialize() {
      this.rankingService = Rankings;
      this.harvestService = Harvests;
      this.userService = Users;
      this.payload = this.rankingService.newData();

      this.getCurrentHarvest();
      this.getHarvests();
      this.getSuccessAdvisors();
      this.getNationalRanking();
    },

    saveFilters(){
      this.selectedRankingType === 'national_ranking' && this.getNationalRanking(this.currentHarvest);
      this.selectedRankingType === 'regional_ranking' && this.getRegionalRanking(this.selectedAS, this.currentHarvest)
      this.selectedRankingType === 'state_ranking' && this.getStateRanking(this.selectedState, this.currentHarvest);

      this.changeIql()
    }
  },

  beforeMount() {
    this.authoritySlug = getItem('authoritySlug');
    this.permissions = JSON.parse(getItem('permissions'));
    this.initialize();
  }
}
</script>

<style scoped>
.v-application a {
  color: #262532;
}

::v-deep table {
  overflow: hidden;
}

::v-deep th {
  white-space: nowrap;
}

::v-deep th:last-of-type,
::v-deep td:last-of-type {
  padding-right: 0px !important;
}

::v-deep .custom-tertiary-input+.v-card {
  width: 120px !important;
}
</style>
import { render, staticRenderFns } from "./RankingsTableAS.vue?vue&type=template&id=08f2ec04&scoped=true&"
import script from "./RankingsTableAS.vue?vue&type=script&lang=js&"
export * from "./RankingsTableAS.vue?vue&type=script&lang=js&"
import style0 from "./RankingsTableAS.vue?vue&type=style&index=0&id=08f2ec04&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f2ec04",
  null
  
)

export default component.exports
<template>
  <div>
    <toolbar :toolbarName="dynamicToolbarName" :openDetails="true" />

    <search-bar
      v-if="!openDetails"
      v-model="search"
      @externalFiltersClear="clearFilters"
      :noFourthInput="false"
    >
      <template slot="fourth-input">
        <simple-select
          v-model="currentHarvest"
          :items="this.harvests.map((item) => item.harvest)"
          itemText="harvest"
          itemValue="harvest"
          placeholder="Safra"
          height="0"
          :menuProps="{bottom: true, offsetY: true, closeOnClick: true, maxHeight: 200}"
          @custom-change="changeHarvest()"
        />
      </template>
    </search-bar>

    <v-tabs
      fixed-tabs
      background-color="#e3e3e3"
      ligth
      class="font-weight-bold"
      v-model="selectedIql"
      @change="changeIql()"
    >
      <v-tab>
        IQL Geral
      </v-tab>
      <v-tab>
        IQL Fertilidade
      </v-tab>
      <v-tab>
        IQL Manejo
      </v-tab>
      <v-tab>
        IQL Financeiro
      </v-tab>
    </v-tabs>

    <v-data-table
      :key="componentKey"
      :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      }"
      :headers="headers"
      :items="data"
      :search="search.text"
      :loading="fetching"
      :loading-text="
        objectName
          ? 'Carregando dados de ' + objectName + ', por favor aguarde...'
          : 'Carregando dados, por favor aguarde...'
      "
      class="px-6 pt-6 yellow-border rounded-xl"
      no-data-text="Não foram encontrados registros."
      no-results-text="Nenhum registro bate com a busca."
    >

      <template v-slot:[`item.position`]="{ item }">
        {{ item.position }}º
      </template>

      <template v-slot:[`item.fertility_iql`]="{ item }">
        <router-link to="" @click.native="redirectFertility(item.farm_id, item.harvest)">
          {{ item.fertility_iql }}
        </router-link>
      </template>

      <template v-slot:[`item.management_iql`]="{ item }">
        <router-link to="" @click.native="redirectManagement()">
          {{ item.management_iql }}
        </router-link>
      </template>

      <template v-slot:[`item.financial_iql`]="{ item }">
        <router-link to="" @click.native="redirectFinancial(item.farm_id, item.harvest)">
          {{ item.financial_iql }}
        </router-link>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import Toolbar from "@/components/crud/Toolbar";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";

import Rankings from "@/domain/rankings/rankings";
import Harvests from "@/domain/harvests/harvests";

import PageableMixin from "@/support/utils/pageable.mixin";
import {getItem} from '@/services/local-storage.service';

export default {
  name: "RankingsTableAS",

  mixins: [PageableMixin],

  components: {
    Toolbar,
    SearchBar,
    SimpleSelect,
  },

  data: () => ({
    dynamicToolbarName: "Ranking de Fazendas",
    openDetails: false,
    objectName: "ranking de fazendas",

    search: {},
    fetching: true,

    headers: [
      { text: "Posição", value: 'position',sortable:false },
      { text: "Fazenda", value: "farm",sortable:false },
      { text: "Proprietário", value: "producer",sortable:false },
      { text: "IQL Geral", value: "farm_iql" },
      { text: "IQL Fertilidade", value: "fertility_iql" },
      { text: "IQL Manejo", value: "management_iql" },
      { text: "IQL Financeiro", value: "financial_iql" },
    ],

    selectedIql: 0,

    iqls: [
      { text: "IQL Geral", value: "farm_iql" },
      { text: "IQL Fertilidade", value: "fertility_iql" },
      { text: "IQL Manejo", value: "management_iql" },
      { text: "IQL Financeiro", value: "financial_iql" },
    ],

    selectedRankingType: "regional_ranking",

    rankingService: null,
    harvestService: null,
    currentHarvest: null,
    selectedAS: null,

    payload: {},
    data: [],
    harvests: [],
    successAdvisors: [],
    states: [],

    componentKey: 0,

    requiredRules: [(v) => !!v || v === false || "O campo é obrigatório"],
  }),

  methods: {
    
    async getHarvests() {
      const loader = this.$loading.show();
      this.harvests = [];

      await this.harvestService.all().then((result) => {
        this.harvests = result.data;
      });

      loader.hide();
    },

    async getRegionalRanking() {
      this.fetching = true;
      this.data = [];

      await this.rankingService
        .getRegionalRanking(this.selectedAS, this.currentHarvest)
        .then((result) => {
          this.data = this.setPositions(result,'farm_iql')
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });

      this.fetching = false;
    },

    changeIql() {      
      const mapIql=['farm_iql','fertility_iql','management_iql','financial_iql'];
      const iql = mapIql[this.selectedIql];
      
      this.data.sort((a, b) => 
        {
          const valueA = typeof(a[iql]) === 'string' ? -10 :  a[iql];
          const valueB = typeof(b[iql]) === 'string' ? -10 :  b[iql];
          return valueB - valueA
        }
      );

      this.data = this.setPositions(this.data,iql);   
      this.componentKey += 1;
    },

    setPositions(data, iql){
      let newData;
      if(iql === 'farm_iql')
        newData = this.sortFarmIql(data);
      else
        newData = this.sortIql(data,iql)
      
      return newData
    },
    sortIql(data,iql){
      let iqlValue = 0;
      let position = 0;
      const newData = data.map((ranking, index)=>{
        if(ranking[iql] != iqlValue){
          iqlValue = ranking[iql]
          position = index+1
          return {...ranking, position}
        }
        return {...ranking, position}
      })
      return newData;      
    },
    sortFarmIql(data){
      let position = 0;
      let farmIql = 0;
      let fertilityIql = 0;
      let managementIql  = 0;
      let financialIql = 0;
      const newData = data.map((ranking, index)=>{        
        if(ranking.farm_iql != farmIql){ //Primeiro critério
          farmIql = ranking.farm_iql;
          fertilityIql = ranking.fertility_iql;
          managementIql  = ranking.management_iql;
          financialIql = ranking.financial_iql;

          position = index+1 
          return {...ranking, position}
        }
        if(ranking.fertility_iql != fertilityIql){
          fertilityIql = ranking.fertility_iql;
          managementIql  = ranking.management_iql;
          financialIql = ranking.financial_iql;

          position = index+1 
          return {...ranking, position}
        }
        if(ranking.management_iql != managementIql){
          managementIql  = ranking.management_iql;
          financialIql = ranking.financial_iql;

          position = index+1 
          return {...ranking, position}
        }
        if(ranking.financial_iql != financialIql){
          financialIql = ranking.financial_iql;

          position = index+1 
          return {...ranking, position}
        }
        return {...ranking, position}
      })
      return newData;
    },

    changeHarvest() {
      this.getRegionalRanking(this.selectedAS, this.currentHarvest);
    },

    getCurrentHarvest() {
      const date  = new Date();
      let year    = date.getFullYear();
      const month = date.getMonth() + 1;

      // safra (ano da colheita) vai de agosto a julho
      if ([8, 9, 10, 11, 12].includes(month)) {
        this.currentHarvest = year + 1;
      } else if ([1, 2, 3, 4, 5, 6, 7].includes(month)) {
        this.currentHarvest = year;
      }
    },

    redirectFertility(farmId, harvest) {
      this.navigateTo("fertility-record-details", {farmId: farmId, harvest: harvest});
    },

    redirectManagement() {
      this.navigateTo("management-records");
    },

    redirectFinancial(farmId, harvest) {
      this.navigateTo("financial-record-details", {farmId: farmId, harvest: harvest});
    },

    initialize() {
      this.rankingService = Rankings;
      this.harvestService = Harvests;
      this.payload = this.rankingService.newData();

      this.getCurrentHarvest();
      this.getHarvests();
      this.getRegionalRanking();
    },

    clearFilters() {
      this.search = {};
      this.selectedIql = "farm_iql";
      this.data.sort((a, b) => b.farm_iql - a.farm_iql);

      const harvest = this.currentHarvest;
      this.getCurrentHarvest();

      if (harvest !== this.currentHarvest) {
        this.componentKey += 1;
        this.getRegionalRanking();
      }
    },
  },

  beforeMount() {
    const user = JSON.parse(getItem('user'));
    this.successAdvisors.push({'id': user.id, 'name': user.name });
    this.selectedAS = user.id;
    this.initialize();
  },
}
</script>

<style scoped>
  .v-application a {
    color: #262532;
  }

  ::v-deep table {
    overflow: hidden;
  }

  ::v-deep th {
    white-space: nowrap;
  }

  ::v-deep th:last-of-type,
  ::v-deep td:last-of-type {
    padding-right: 0px !important;
  }

  ::v-deep .custom-tertiary-input + .v-card {
    width: 120px !important;
  }
</style>